import { useEffect } from "react";
import { Section } from "react-scroll-section";
import styles from "../css/home.module.css";

const games = [
    {
        title: "Balls Pop",
        image: "/images/game1.png",
        ios_link: "https://apps.apple.com/app/balls-pop-blast/id1525863083",
        google_link: "https://play.google.com/store/apps/details?id=pop.balls.bba",
        description:
            "balls pop is a fun puzzle game based on physics and gravity where you tap 2 or more marbles of the same color to make them pop and complete different objectives to complete the level",
    },
    {
        title: "Cubes Blast",
        image: "/images/game2.png",
        ios_link: "https://apps.apple.com/app/cube-blast-match-pop-puzzle/id1557627778",
        google_link: "https://play.google.com/store/apps/details?id=blast.block.match.cube.gpa",
        description: "cubes blast is casual game that will challenge your brain to plan the next move to collect all the level objectives",
    },
    {
        title: "Bubble Shooter",
        image: "/images/game3.png",
        ios_link: "https://apps.apple.com/us/app/bubble-shooter-pop-balls/id1574687775",
        google_link: "https://play.google.com/store/apps/details?id=bubble.shooter.pop.shoot.puzzle",
        description: "aim and shoot bubbles in a fun bubble shooter game with smooth gameplay, solve fun and challenging levels in a calming purple theme graphic design",
    },
];

export function Home() {
    useEffect(() => {
        document.title = 'Home'
    });

    const submitForm = () => {
        console.log("Contact form submitted");
    };

    return (
        <div className={styles.home}>
            <div className={styles.top_section}>
                <img src="/images/top_section.png" width={166} height={166} />
                <div className={styles.right_container}>
                    <p className={styles.quote}>"Topit is a pocket installed inside a magician jacket used to dispose of objects secretly"</p>

                    <p className={styles.description}>
                        we are a group of talented game industry veterans based in tel aviv israel that does magical things.
                        <br></br>
                        we aim to create fun high quality casual games
                    </p>
                </div>
            </div>

            <Section id="games">
                <div className={styles.games_section}>
                    <div className={styles.header}>Games</div>

                    {games.map((game, index) => (
                        <div className={styles.game} style={{ backgroundColor: index % 2 === 0 ? "rgb(56,74,211)" : "rgb(19, 25, 70)" }}>
                            <div className={styles.wrapper}>
                                <img src={game.image} />

                                <div className={styles.right_container}>
                                    <h1>{game.title}</h1>

                                    <p>{game.description}</p>

                                    <a href={game.ios_link} target="_blank">
                                        <img src="/images/apple_store_download.png" width={164} height={54} />
                                    </a>
                                    <a href={game.google_link} target="_blank">
                                        <img src="/images/googleplay_download.png" width={164} height={54} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Section>

            <Section id="career">
                <div className={styles.career_section}>
                    <h1>Career</h1>

                    <p>we are looking for a talented game artist that specializes in casual mobile games to join us and design our upcoming games</p>
                </div>
            </Section>

            <Section id="contact">
                <div className={styles.contact_section}>
                    <h1>Contact us</h1>

                    <div className={styles.form}>
                        <div>
                            <input placeholder="Name" name="name" type="text" />
                            <input placeholder="Email Address*" name="email" type="email" />
                        </div>

                        <textarea placeholder="Message" name="message" cols="50" rows="10"></textarea>

                        <div className={styles.button_container}>
                            <button onClick={submitForm}>Submit</button>
                        </div>
                    </div>
                </div>
            </Section>
        </div>
    );
}
