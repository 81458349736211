import { useEffect } from "react";
import styles from "../css/privacy-policy.module.css";

export function PrivacyPolicy() {
    useEffect(() => {
        document.title = "Privacy Policy";
    });

    return (
        <div className={styles.privacy_policy}>
            <h1>PRIVACY POLICY</h1>
            <p>
                the privacy policy (“Privacy Policy”) explain how we collects, stores, and uses the information collected in connection with our Services.<br></br>
                BY INSTALLING, USING, REGISTERING TO OR OTHERWISE ACCESSING THE SERVICES, YOU AGREE TO THIS PRIVACY POLICY AND GIVE AN EXPLICIT AND INFORMED CONSENT TO THE PROCESSING OF YOUR PERSONAL
                DATA IN ACCORDANCE WITH THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT INSTALL, USE, REGISTER TO OR OTHERWISE ACCESS THE SERVICES. we reserves the right
                to modify this Privacy Policy at reasonable times, so please review it frequently. If we makes material or significant changes to this Privacy Policy, Your continued use of Services
                will signify your acceptance of the changes to this Privacy Policy.<br></br>
                Non-personal data<br></br>
                For purposes of this Privacy Policy, “non-personal data” means information that does not directly identify you. The types of non-personal data we may collect and use include, but are
                not limited to: application properties, including, but not limited to application name, package name and icon installed on your device. Your checkin (include like, recommendation) of a
                game will be disclosed to all our users. we may use and disclose to our partners and contractors the collected non-personal data for purposes of analyzing usage of the Services,
                advertisement serving, managing and providing the Services and to further develop the Services and other services and products. You recognize and agree that the analytics companies
                utilized by us may combine the information collected with other information they have independently collected from other services or products relating to your activities. These
                companies collect and use information under their own privacy policies.<br></br>
                Personal Data<br></br>
                For purposes of this Privacy Policy, “personal data” means personally identifiable information that specifically identifies you as an individual. Personal information collected by us
                is information voluntarily provided to us by you when you create your account or change your account information. The information includes your facebook id, name, gender, location and
                your friends’id on facebook. we also stores your game checkins, likes, dislikes, recommendations and messages. we may use collected personal data for purposes of analyzing usage of the
                Services, providing customer and technical support, managing and providing Services (including managing advertisement serving) and to further develop the Services and other services
                and products. we may combine non-personal data with personal data. Please note that certain features of the Services may be able to connect to your social networking sites to obtain
                additional information about you. In such cases, we may be able to collect certain information from your social networking profile when your social networking site permits it, and when
                you consent to allow your social networking site to make that information available to us. This information may include, but is not limited to, your name, profile picture, gender, user
                ID, email address, your country, your language, your time zone, the organizations and links on your profile page, the names and profile pictures of your social networking site
                “friends” and other information you have included in your social networking site profile. we may associate and/or combine as well as use information collected by us and/or obtained
                through such social networking sites in accordance with this Privacy Policy.<br></br>
                Disclosure and Transfer of Personal Data<br></br>
                we collects and processes personal data on a voluntary basis and it is not in the business of selling your personal data to third parties. Personal data may, however, occasionally be
                disclosed in accordance with applicable legislation and this Privacy Policy. Additionally, we may disclose personal data to its parent companies and its subsidiaries in accordance with
                this Privacy Policy. we may hire agents and contractors to collect and process personal data on our behalf and in such cases such agents and contractors will be instructed to comply
                with our Privacy Policy and to use personal data only for the purposes for which the third party has been engaged by us. These agents and contractors may not use your personal data for
                their own marketing purposes. we may use third party service providers such as credit card processors, e-mail service providers, shipping agents, data analyzers and business
                intelligence providers. we has the right to share your personal data as necessary for the aforementioned third parties to provide their services for us. we are not liable for the acts
                and omissions of these third parties, except as provided by mandatory law. we may disclose your personal data to third parties as required by law enforcement or other government
                officials in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose you or us to legal liability. we may also
                disclose your personal data to third parties when we has a reason to believe that a disclosure is necessary to address potential or actual injury or interference with our rights,
                property, operations, users or others who may be harmed or may suffer loss or damage, or we believes that such disclosure is necessary to protect our rights, combat fraud and/or comply
                with a judicial proceeding, court order, or legal process served on us. To the extent permitted by applicable law.<br></br>
                Safeguards<br></br>
                we follows generally accepted industry standards and maintains reasonable safeguards to attempt to ensure the security, integrity and privacy of the information in our possession. Only
                those persons with a need to process your personal data in connection with the fulfillment of their tasks in accordance with the purposes of this Privacy Policy and for the purposes of
                performing technical maintenance, have access to your personal data in our possession. Personal data collected by us is stored in secure operating environments that are not available
                to the public. To prevent unauthorized on-line access to personal data, we maintains personal data behind a firewall-protected server. However, no system can be 100% secure and there
                is the possibility that despite our reasonable efforts, there could be unauthorized access to your personal data. By using the Services, you assume this risk.<br></br>
                Other<br></br>
                Please be aware of the open nature of certain social networking and other open features of the Services we may make available to you. You may choose to disclose data about yourself in
                the course of contributing user generated content to the Services. Any data that you disclose in any of these forums, blogs, chats or the like is public information, and there is no
                expectation of privacy or confidentiality. we are not responsible for any personal data you choose to make public in any of these forums. If you are under 15 years of age or a minor in
                your country of residence, please ask your legal guardian’s permission to use or access the Services. we takes children’s privacy seriously, and encourages parents and/or guardians to
                play an active role in their children's online experience at all times. we are not knowingly collecting any personal information from children below the aforementioned age and if we
                learns that we has inadvertently gathered personal data from children under the aforementioned age, we will take reasonable measures to promptly erase such personal data from our
                records. we may store and/or transfer your personal data to its affiliates and partners in and outside of EU/EEA member states and the United States in accordance with mandatory
                legislation and this Privacy Policy. we may disclose your personal data to third parties in connection with a corporate merger, consolidation, restructuring, the sale of substantially
                all of our stock and/or assets or other corporate change, including, without limitation, during the course of any due diligence process provided, however, that this Privacy Policy
                shall continue to govern such personal data. we regularly reviews its compliance with this Privacy Policy. If we receives a formal written complaint from you, it is our policy to
                attempt to contact you directly to address any of your concerns. we will cooperate with the appropriate governmental authorities, including data protection authorities, to resolve any
                complaints regarding the collection, use, transfer or disclosure of personal data that cannot be amicably resolved between you and us.<br></br>
                Here are links to our partners' Privacy Policy:<br></br>
                https://policies.google.com/privacy<br></br>
                https://www.facebook.com/privacy/explanation<br></br>
                https://www.applovin.com/privacy/<br></br>
                https://unity3d.com/legal/privacy-policy
            </p>
        </div>
    );
}
