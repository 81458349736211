import "./css/globals.css";
import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ScrollingProvider } from "react-scroll-section";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollingProvider>
                <App />
            </ScrollingProvider>
        </BrowserRouter>
    </React.StrictMode>
);
