import { useEffect } from "react";
import styles from "../css/privacy-policy.module.css";

export function DataDeletion() {
    useEffect(() => {
        document.title = "Privacy Policy";
    });

    return (
        <div className={styles.privacy_policy}>
            <h1>DATA DELETION POLICY</h1>
            <p>
            Under the General Data Protection Regulation, you have the right to have all of your personal
            or your children's data used in our application and our partners' services.

            We do not collect or save personal data.
            You may request to delete your personal data by contacting our support team in the following mail:
            <br></br>
            gamespiger@gmail.com
            </p>
        </div>
    );
}
