import { Routes, Route, Link } from "react-router-dom";
import { Home } from "./pages/home";
import { PrivacyPolicy } from "./pages/privacy-policy";
import { TermsOfService } from "./pages/terms-of-service";
import styles from "./css/home.module.css";
import { useScrollSection } from "react-scroll-section";
import { DataDeletion } from "./pages/data-deletion";

function App() {
    const homeSection = useScrollSection("home");
    const gamesSection = useScrollSection("games");
    const careerSection = useScrollSection("career");
    const contactSection = useScrollSection("contact");

    return (
        <div className="App">
            <div className={styles.navigation}>
                <h1 hidden={window.innerWidth < 768}>TOPIT STUDIO</h1>

                <ul className={window.innerWidth > 768 ? styles.links : styles.mobile_links}>
                    <Link to="/">
                        <li onClick={homeSection.onClick} selected={homeSection.selected}>
                            Home
                        </li>
                    </Link>

                    <Link to="/">
                        <li onClick={gamesSection.onClick} selected={gamesSection.selected}>
                            Games
                        </li>
                    </Link>

                    <Link to="/">
                        <li onClick={careerSection.onClick} selected={careerSection.selected}>
                            Career
                        </li>
                    </Link>

                    <Link to="/">
                        <li onClick={contactSection.onClick} selected={contactSection.selected}>
                            Contact
                        </li>
                    </Link>
                </ul>
            </div>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/data-deletion" element={<DataDeletion />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
            </Routes>

            <div className={styles.footer}>
                <p>© 2021 topit</p>

                <ul>
                    <Link to="/terms-of-service">
                        <li>Terms of Service</li>
                    </Link>

                    <Link to="/privacy-policy">
                        <li>Privacy Policy</li>
                    </Link>
                </ul>
            </div>
        </div>
    );
}

export default App;
